@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

/* html{
  width:100%;
  height:100%;
} */
*:focus {
  outline: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

pre {
  overflow-y: hidden !important;
}

.mainDiv {
  margin-top: 2%;
}

.mainDivTwo {
  width: 850px;
  position: relative;
}

/* .userLogin {
  width: 40px;
  height: 40px;
  background-color: green;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
} */
/* .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.frontScreen {
  /* width:100%;
  height:100%; */
  margin-top: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dbLogoDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 8%;
}

.frontScreen .dbLogo {
  font-size: 60px;
  margin-bottom: 5%;
}

.helpulDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.helpulDiv1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:15px;
  /* justify-content: space-between; */
}
.thankdFeedBackDiv{
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:15px
}

.frontScreenBox {
  border-radius: 10px;
  padding: 3%;
  /* cursor: pointer; */
  margin: 4px 0;
  text-align: center;
}

.cc {
  padding: 0 4px;
}

.frontScreenBox p {
  margin: 0;
}

.bottomArea {
  margin: 2%;
  display: flex;
  justify-content: center;
  position: relative;
}

.bottomArea .selectFile {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 5px 5px;
  border-radius: 25px;
  width: 850px;
  position: fixed;
  bottom: 1%;
  z-index: 998;
}

.bottomArea .selectFile1 {
  display: flex;
  align-items: center;
  /* background-color: #f2f2f2; */
  justify-content: space-between;
  padding: 5px 5px;
  border-radius: 25px;
  width: 850px;
  /* position: fixed; */
  bottom: 1%;
  z-index: 998;
}

.FeedBackDiv {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  padding: 10px;
  color: gray;
  font-size: 14px;
  text-align: center;
}

.Ask_Another_Doubt {
  width: 100%;
  padding: 10px;
  border-radius: 13px;
  background-color:rgb(36, 84, 221);
  border-color: rgb(36, 84, 221);
  color:#fff;
  font-size: 14px;
  font-weight: 700;
}

.icon-green {
  color: green;
}

.icon-red {
  color: red;
}

.red-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 56px;
  gap: 3px;
  padding: 6px;
  border-radius: 50px;
  font-size: 14px;
  background-color: white;
  border: 1px solid rgb(225, 61, 93);
  color:rgb(225, 61, 93);
}

.green-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 56px;
  gap: 3px;
  padding: 6px;
  border-radius: 50px;
  font-size: 14px;
  border: 1px solid #71e271;
  background: white;
  color:#71e271;
}
.was_helpful {
  font-size: 16px;
  margin-right: 20px;
  margin-top: 8.5px;
  text-align: center;
  color: rgb(0, 0, 0);
}

.feedBack-Button {
  display: flex;
  flex-direction: row;
  gap: 10px
}

/* .eqPadding {
  padding: 7px 12px;
}
.eqPadding i {
  font-size: 16px;
} */
.attachArea {
  border: none;
  background: none;
  cursor: pointer;
}

.renderButtonOne {
  border-radius: 100px;
  border: none;
  cursor: pointer;
}

.renderButtonTwo {
  border-radius: 100px;
  border: none;
  background-color: #f2f2;
  cursor: pointer;
}

.inputArea {
  border: none;
  background-color: #f2f2f2;
  outline: none;
  width: 100%;
  padding: 10px 12px 10px 0px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.solutionDiv {
  background-color: #F1F4FD;
  border-radius: 10px;
  padding: 2%;
  margin-top: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* border:1px solid black; */
}

.solutionDiv pre {
  margin-bottom: 0;
  position: relative;
}

.followupButton {
  /* position:absolute; */
  /* right:0; */
  /* padding-left:4px; */
  cursor: pointer;
  border: 1px solid #2454DD;
  border-radius: 6px;
  padding: 0 5px;
  color: #2454DD;
  font-size: 12px;
  background-color: #FFF9C4;
}

.imageDiv {
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.imageDiv img {
  border-radius: 10px;
}

.questionDiv {
  background-color: #F1F4FD;
  border-radius: 10px;
  padding: 2%;
  margin-top: 20px;
  margin-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.questionDiv h6 {
  line-height: 1.4;
  font-size: .950em
}

.solutionNameHead {
  margin: 20px 5px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-down {
  padding: 5px;
}

.imageFormate {
  margin: 20px 0;
}

.textFormate {
  margin: 20px 0;
}

.apiLogin {
  width: 550px;
}

.spinner-grow {
  margin: 2px;
  width: 5px;
  height: 5px;
}

.processing {
  margin: 0 0 10px 5px;
}

.commonProcessing {
  margin: 3px 0 5px 0;
}

.talkDiv {
  position: relative;
  margin-top: 15px;
  /* margin-left:15px; */
}

.askQuestion {
  background-color: #F3F3F3;
  border-radius: 10px;
  padding: 2%;
  margin: 10px 0;
  max-width: 85%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.aiSolution {
  background-color: #F4F4F4;
  border-radius: 10px;
  padding: 2%;
  margin: 10px 0;
  max-width: 85%;
  margin-top: 0px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.stepAskDiv {
  position: relative;
  display: none;
}

.MJXc-display {
  display: inline !important;
  margin: 0 !important;
}

.aiForm .aiInputArea {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid gray;
}

.aiForm button {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid gray;
}

.aiForm {
  display: flex;
  gap: 10px;
}

.qDiv {
  color: #2454DD;
  margin-right: 5px;
}

#MathJax_Message {
  display: none !important;
}

.btnFont {
  font-size: 20px;
}

/* .img-preview {
  overflow: hidden;
} */
.optionDiv {
  display: flex;
  justify-content: end;
  background-color: #f1f4fd;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cropDiv {
  z-index: 1000;
  width: 850px;
  border: 1px solid #f2f2;
  border-radius: 10px;
  background-color: #f1f4fd;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: 5%;
  overflow: scroll;
  padding: 1%
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.cropAreaFooter {
  margin: 10px;
  /* margin-top:2%; */
  display: flex;
  gap: 10px;
  justify-content: center;
}

.cameraBody {
  display: flex;
  justify-content: center;
}

.cameraBtn {
  position: absolute;
  bottom: 8%;
  border-radius: 100px;
  z-index: 99999;
}

.cameraDiv {
  display: flex;
  justify-content: center;
}

.cameraOpen {
  z-index: 1200;
}

.eqlWZp {
  display: flex;
  justify-content: center;
}

.noCamera {
  display: flex;
  align-items: center;
  /* gap:5px; */
  position: absolute;
  top: 5px;
  padding: 5px 15px !important;
  /* text-align:center; */
  /* border:1px solid #f2f2f2; */
  /* border-radius:20px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.alert-dismissible .btn-close {
  position: relative !important;
}

.dZaikO {
  position: absolute;
  top: 5px;
  padding: 5px 15px !important;
  text-align: center;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popupContent {
  display: flex;
  flex-direction: column;
}

.popupIcon {
  width: 24px;
  height: 24px;
}

.offcanvas {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.autoSuggestion p {
  margin: 0;
  color: #7D8592;
  font-size: .875em;
}

.autoSuggestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #7D8592;
  padding: 8px 0;
  cursor: pointer;
  width: 100%;
  border-radius: 0;
  text-align: left;
}

.autoSuggestion:hover {
  background-color: #f2f2f2;
}

.followupInputDiv {
  background-color: white;
  border-radius: 20px;
  width: 100%;
  padding: 0 5px;
  margin-top: 15px;
}

.followupInput {
  border: none;
  background-color: white;
  outline: none;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
}

/**************** Cropper Box Css **********************/
.cropper-point.point-se::before {
  background-color: #39f;
  bottom: -50%;
  content: ' ';
  display: none !important;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}

.cropper-line {
  opacity: 0.5 !important;
  width: 100% !important;
  height: 100% !important;
  visibility: hidden;
}

.line-e::before {
  position: absolute !important;
  content: ' ';
  top: 4px !important;
  height: 15px !important;
  width: 15px !important;
  left: 1.4px !important;
  visibility: visible;
  border-left: 3px solid rgb(21, 3, 49);
  border-top: 3px solid rgb(21, 3, 49);
}

.line-n::before {
  content: ' ';
  bottom: 1px !important;
  display: block !important;
  height: 15px !important;
  width: 15px !important;
  position: absolute !important;
  left: 4px !important;
  visibility: visible;
  border-left: 3px solid rgb(21, 3, 49);
  border-bottom: 3px solid rgb(21, 3, 49);
}

.line-w::before {
  content: ' ';
  top: 3.8px !important;
  display: block !important;
  height: 15px !important;
  width: 15px !important;
  position: absolute !important;
  right: 1.5px !important;
  visibility: visible;
  border-top: 3px solid rgb(21, 3, 49);
  border-right: 3px solid rgb(21, 3, 49);
}

.line-s::before {
  content: ' ';
  bottom: 7px !important;
  display: block !important;
  height: 15px !important;
  width: 15px !important;
  position: absolute !important;
  right: 4.5px !important;
  visibility: visible;
  border-bottom: 3px solid rgb(21, 3, 49);
  border-right: 3px solid rgb(21, 3, 49);
}

.cropper-point {
  /* background-color: rgb(123, 42, 215) !important; */
  height: 50px !important;
  opacity: 0 !important;
  width: 50px !important;
  position: relative;
}

.point-ne {
  position: relative;
  top: -25px !important;
  opacity: 0 !important;
  right: -25px !important;
  z-index: 1;
}

.point-nw {
  top: -25px !important;
  opacity: 0 !important;
  left: -25px !important;
}

.point-sw {
  bottom: -25px !important;
  opacity: 0 !important;
  left: -25px !important;
}

.point-se {
  bottom: -25px !important;
  opacity: 0 !important;
  right: -25px !important;
}

.point-e {
  margin-top: 0px !important;
  right: -25px !important;
  top: 0% !important;
  height: 100% !important;
}

.point-n {
  left: 0% !important;
  margin-left: 0px !important;
  top: -25px !important;
  width: 100% !important;
}

.point-w {
  left: -25px !important;
  margin-top: 0px !important;
  top: 0% !important;
  height: 100% !important;
}

.point-s {
  bottom: -25px !important;
  left: 0% !important;
  margin-left: 0px !important;
  width: 100% !important;
}

/**********************************************/

@media screen and (max-width: 880px) {
  .mainDivTwo {
    width: 100%;
  }

  .frontScreenBox {
    width: 100%;
  }

  .bottomArea .selectFile {
    width: 92%;
  }

  .bottomArea .selectFile1 {
    width: 92%;
  }

  .mainDivTwo {
    padding: 4%;
  }

  .cropDiv {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .frontScreen {
    margin-top: 20vw;
  }

  .apiLogin {
    width: 100%;
  }

  .questionDiv {
    padding: 4%;
    margin-top: 15px;
  }

  .solutionDiv {
    padding: 4%;
    margin-top: 15px;
  }

  .askQuestion {
    padding: 4%;
    max-width: 95%;
  }

  .aiSolution {
    padding: 4%;
    max-width: 95%;
  }

  .bottomArea .selectFile {
    padding: 3px 3px;
  }

  .bottomArea .selectFile1 {
    padding: 3px 3px;
  }

  .inputArea {
    padding: 10px 12px 10px 0px;
  }

  .cropDiv {
    width: 90%;
  }

  .dZaikO {
    width: 90%;
    top: 3%;
  }

  .noCamera {
    width: 90%;
    top: 3%;
  }
}


.free-doubt-main{
  font-family: 'Open Sauce One', sans-serif;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
}
 
.free-heading{
  font-size: 16px;
  margin-top: 10px;
}

.free-text1{
  font-size: 12px;
  margin-bottom: 24px;
}

.free-text2 {
  font-size: 12px;
  line-height: 14.84px;
}

.free-btn-container{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
/* #video, #canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 999;
} */

video {
  width: 100%;
}

button {
  background-color: rgba(0, 150, 0, 0.5);
  color: white;
  padding: .5rem 3rem;
}


#clickbutton {
  display:block;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  top: 4rem;
}


#camera {
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  z-index: 1200;
}


/* downtime */


/* .dcontainer {
  font-family: Arial, sans-serif;
display: flex;
flex-direction: column;
padding: auto;

justify-content: center;


}

.dcard {

border-radius: 10px;

text-align: center;
display: flex;
flex-direction: column;


}

h1 {
font-size: 24px;
margin-bottom: 20px;
color: #333;
font-weight: bold;
}

p {
color: #555;
margin: 10px 0;

} */





















.dcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  height: 100vh;
  padding: 20px; 
  text-align: center; 

}
.downttime-text{
  font-family: 'Poppins', sans-serif;
}

/* .dcard {
  margin-bottom: 20px; 
 
} */
h1 {
  font-size: 24px;
  /* margin-bottom: 20px; */
  color: #333;
  font-weight: bold;
  
  }

.icon {
  width: 100%; 
  max-width: 300px; 
  height: auto;
  object-fit: contain;
  margin-bottom: 33px;
}

/* .text {
  margin-bottom: 10px; 
  
} */

.btn {
  margin-top: 10px; 
}

.retry-button {
  padding: 10px 20px; 
  background: #2454dd; 
  color: white;
  font-weight: bold;
  border: none; 
  border-radius: 12px;
  cursor: pointer; 
}


@media (max-width: 768px) {
  .dcard {
    padding: 10px; 
  }

  .icon {
    width: 90%; 
    max-width: 100%; 
  }

  .text {
    padding: 0 10px; 
  }

  .retry-button {
    width: 100%; 
  }
}
