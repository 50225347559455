.bot-warning-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .bot-warning-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
  }
  
  .warning-icon {
    width: 48px;
    height: 48px;
    color: #dc3545;
    margin-bottom: 1rem;
  }
  
  h2 {
    color: #dc3545;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  p {
    color: #6c757d;
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  
  .support-options {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  .support-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .support-button:not(.secondary) {
    background-color: #0d6efd;
    color: white;
  }
  
  .support-button.secondary {
    background-color: #f8f9fa;
    color: #212529;
    border: 1px solid #dee2e6;
  }
  
  .support-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }